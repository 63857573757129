/* progressbar 위치 조정 */
.swiper-container {
    position: relative;
}

.swiper-wrapper {

    /* 슬라이드가 컨테이너 밖으로 넘어가지 않도록 설정 */
}

.swiper-pagination {
    position: absolute !important;
    bottom: 0 !important;
    left: 0;
    width: 100%;
    height: 4px;
    /* 높이 강제 조정 */
}

.swiper-pagination-horizontal {
    position: absolute;
    bottom: 0 !important;
    left: 0;
    width: 100%;
    height: 4px;
    background: lightgray;
}

.swiper-pagination-progressbar {
    position: absolute;
    bottom: 0 !important;
    left: 0;
    width: 100%;
    height: 4px;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: gray;
}

/* 네비게이션 버튼 기본적으로 숨기기 */
.swiper-button-next,
.swiper-button-prev {
    color: gray;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 10;
    /* 다른 요소 위에 표시되도록 */
}


/* Swiper 전체에 hover 시 네비게이션 버튼 표시 */
.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
    opacity: 1;
}