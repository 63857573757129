@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    @media (min-width:1800px) {
        font-size: 62.5%;
    }

    @media (min-width:1420px) and (max-width:1799px) {
        font-size: 46.8%;
    }

    @media (min-width:900px) and (max-width:1419px) {
        font-size: 42%;
    }

    @media (min-width: 768px) and (max-width:899px) {
        font-size: 35%;
    }

    @media (max-width:767px) {
        font-size: 32%;
    }

}

body,
div {
    margin: 0;
    padding: 0;
    font-family: 'MazzardM-Regular' !important;

    /* 원하는 폰트로 지정 */
}

/* slick-carousel 스타일 */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";